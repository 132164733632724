

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 *
 * @returns {String}
 */
export default function useOSDetection() {
    if (typeof window !== "undefined" && typeof navigator !== "undefined") {

        var userAgent = navigator.userAgent || navigator.vendor;

        if (/android|Android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod|iOS/.test(userAgent)) {
            return "iOS";
        }
    }

    return "unknown";
}