import './App.css';
import Home from "./pages/home/home";
import React from 'react'
import useGTM from './hooks/UseGTM'
import CookieConsent from "react-cookie-consent";
import { Routes, Route } from 'react-router-dom';
import OnaContact from './pages/OnaContact/OnaContact';


function App() {

  const { removeGA, initializeTagManager } = useGTM()

  return (
    <div container='true' className="body">
      <Routes>
        <Route path='*' element={<Home />} />
        <Route path='application' element={<OnaContact />} />
      </Routes>
      <CookieConsent
        onAccept={initializeTagManager}
        enableDeclineButton
        onDecline={removeGA}
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Refuser"
        cookieName="cookies"
        style={{ background: "#154E76" }}
        declineButtonStyle={{ color: "#000000", background: "#FFFFFF", fontSize: "13px", borderRadius: 20 }}
        buttonStyle={{ color: "#000000", background: "#DEF2FE", fontSize: "13px", borderRadius: 20 }}
        expires={150}
      >
        Ce site utilise des cookies pour améliorer l'expérience utilisateur.
      </CookieConsent>
    </div>
  );
}

export default App;
