import React,  {useState, useEffect} from "react"
import logo from '../../assets/images/logo/desktop.png';
import ios from "../../assets/images/ios.svg"
import android from "../../assets/images/android.svg"
import "./OnaContactStyle.css"
import EmmaIcon from "./EmmaIcon"
import useOSDetection from "../../hooks/useOSDetetction";
import SmileyIcon from "./../../assets/images/SmileyIcon.svg"
import underConstructionR from "../../assets/images/underConstructionR.svg";
import axios from "axios";
export default function OnaContact() {
  const [phone, setPhone] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [smileyIsVisible, setSmileyIsVisible] = useState(false)
  const [isSend, setIsSend] = useState(false)
  const [borderError, setBorderError] = useState(false)
  // const { isSend, setIsSend, Message: SendReturn, setMessage, popMessage } = useSendText()


  const url: string = "https://preprod.tools.ordago.fr/api/v2.0/sms/template/"

  const sendSMS = () => {

    axios.post(url, {
      phone_number: phone,
      template: "SITE_ONA",
      campagne: "Ona application",
      sender: "Ona",
      category: "codeConfirmation"
    }, {
      headers: {
        "Content-Type": 'application/json',
      }
    }).then(() => {
      setIsSend(true)
    }).catch((error) =>{
      console.log(error.message)
    })
  }

  const bordersColors = {
    green: "hover:border-ordago-green-normal focus:border-ordago-green-normal ",
    white: "border-white focus:border-white hover:border-white "
  }

  useEffect(() =>{
    if(isSend){
      setPhone("")
    }
  },[isSend])

  const handleInput = (value) => {
    // setIsSend(false)
    let newValue = value.replace(/\D{0,8}/g, "").substring(0, (beginByZero(value) ? 10 : 9))
    // setPhone(newValue)
  }

  useEffect( () => {
    if(isSend){
      setTimeout(() =>{
        setIsSend(false)
      },[1200])
    }
  },[isSend])

  useEffect(() => {
    let newValue = phone.replace(/\D{0,8}/g, "")
    function numberValidation(phone) {

      if (!beginByZero(newValue)) {
        return newValue.length === 9 && (newValue.slice(0, 1) === "6" || newValue.slice(0, 1) === "7")
      } else if (beginByZero(newValue)) {
        return newValue.length === 10 && (newValue.slice(1, 2) === "6" || newValue.slice(1, 2) === "7")
      }
      return false
    }
    setIsValid(numberValidation(newValue))
  }, [phone])

  useEffect( () =>{
    let value = phone
    if (!value) return value;
    let phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length > 1 && phoneNumber.slice(0, 1) !== "0") {
      phoneNumber = ` ${phoneNumber.substring(0, 9)}`
    }
    const phoneNumberLength = phoneNumber.length;
    switch (true) {
      case phoneNumberLength < 3:
        setPhone(`${phoneNumber.slice(0, 2)}`)
        break;
      case phoneNumberLength < 5:
        setPhone(`${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)}`)
        break;
      case phoneNumberLength < 7:
        setPhone(`${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)}`)
        break;
      case phoneNumberLength < 9:
        setPhone(`${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 8)}`)
        break;
      case phoneNumberLength < 11:
        setPhone(`${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8, 10)}`)
        break;
      default:
        setPhone("")
    }
  },[phone])


  return (<div className="app-page">
    <div className='app-logo'>
      <img style={{width: "100%"}} src={logo} alt="logo de Ona" />
    </div>
    <section className={"app-section "}>
      <div className="app-emma ">
        <EmmaIcon />
      </div>
      <h1 className="app-title">L’application Ona Contact directement sur votre téléphone</h1>
      <small className="app-small">Téléchargez dès maintenant !</small>
      <div className="">
        <DisplayStoreButtons />
      </div>
    </section>
    {!useOSDetection().includes('Android', 'iOS')?
      <section className={"app-section-bis "}>
        <h1 className="app-title">Entrer votre numéro pour recevoir le lien de téléchargement</h1>
        <div style={{width: "100%", height: "45px", justifyContent: "center", alignItems: "center", display: "flex", position: "relative"}}>
          <form style={{display: "flex", borderBottom: "3px solid rgb(255,255,255)", justifyContent: "justify-between", position: "relative", marginLeft: 35, width: "60%", height: 37}}>
            <span style={{ width: 80, height: 45, fontSize: "1.5rem", display: "flex", flexDirection: "column", fontWeight: "bold", justifyContent: "center", color: "white"}}> +33 </span>
            <textarea
              // cssClasses="autofill-dark"
              // cssClassesInput={"font-bold text-2xl mr-5 p-0 placeholder:text-ordago-green-normal placeholder:text-opacity-50 white"}
              cssConstraintLabel="white"
              color={"white"}
              name={"phone"}
              style={{background: "transparent", fontSize: "1.5rem", resize: "none",overflow: "hidden",  borderColor: "rgba(0,0,0,0)", paddingTop: 5, color: 'white'}}
              label={""}
              value={phone}
              type={"tel"}
              placeholder={"06 XX XX XX XX"}
              checkValidity={true}
              isRequired={true}
              onChange={(e) => {
                setPhone((old) => e.target.value)
              }}
              // style={{ resize: "none" }}
              // className={"input pb-0 " + bordersColors[color] + cssValidity + cssClassesInput}
              aria-describedby={"name-validation"}
            />
          </form>
          <div style={{width:"37.5%", paddingLeft: "5%", paddingRight: "5%", height: "100%", borderRadius: "19px"}}>
            <button
              disabled={!isValid}
              onClick={sendSMS}
              style={{width: "100%", height: "85%", borderRadius: "9999px", fontSize: "1rem", letterSpacing: "1.5px", cursor: "pointer" }}>
              Envoyer le message
            </button>

          </div>
          {isSend &&
          <img src={SmileyIcon} style={{position: "absolute", right:"0"}}/>
          }
        </div>
        {isSend &&
        <p style={{color: 'white'}}>
          Envoyé!
        </p>
        }
      </section>
      :
      null
    }
  </div>)
}

export const IOSLink = ({ url }) => <a href={url}><img className="text-white" src={ios} alt="logo de l'Apple Store" /></a>
export const AndroidLink = ({ url }) => <a href={url}><img className="text-white" src={android} alt="logo du Play Store" /></a>

function DisplayStoreButtons() {
  const iosUrl = "#"
  const androidUrl = "#"

  const currentOS = useOSDetection();

  switch (currentOS) {
    case "Android":
      return <button className="app-button"><AndroidLink url={androidUrl} /></button>
    case "iOS":
      return <button className="app-button"><IOSLink url={iosUrl} /></button>
    default:
      return (<>
        <button className="app-button"><IOSLink url={iosUrl} /></button>
        <button className="app-button"><AndroidLink url={androidUrl} /></button>
      </>)
  }
}


/**
 * Check if the first letter is 0
 * @param value string
 * @returns boolean
 */
function beginByZero(value) {
  return value.slice(0, 1) === "0"
}
