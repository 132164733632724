import Cookies from "js-cookie";
import { useEffect } from "react"
import TagManager from "react-gtm-module";

export default function useGTM() {
    const domain = process.env.REACT_APP_DOMAIN_URL
    const tagManagerArgs = {
        gtmId: String(process.env.REACT_APP_TG)
    }

    const removeGA = () => {
        let options = { path: '/', domain: domain }
        Cookies.remove('_ga', options)
        Cookies.remove('_ga_05Z2HE5Y2R', options)
        //remove GA cookies
    };
    const initializeTagManager = () => TagManager.initialize(tagManagerArgs)

    useEffect(() => {
        Cookies.get('cookies') === "true" && initializeTagManager()
    }, [])

    return { removeGA, initializeTagManager }
}
