import React, { useEffect, useState } from 'react'
import { Button, Grid, TextField } from "@material-ui/core";
import underConstruction from '../../assets/images/underConstruction.svg';
import underConstructionR from '../../assets/images/underConstructionR.svg';
import desktop from '../../assets/images/logo/desktop.png';
import { ReactComponent as Smiley } from '../../assets/images/smiley.svg';
import { ReactComponent as Validation } from '../../assets/images/validationMail.svg';
import './home-styles.css';
import axios from "axios";

const Home = () => {
  const [init, setInit] = useState(true)
  const [mail, setMail] = useState('')
  const [goodMail, setGoodMail] = useState(true)
  const [name, setName] = useState('')
  const [goodName, setGoodName] = useState(true)
  const [topic, setTopic] = useState('')
  const [goodTopic, setGoodTopic] = useState(true)
  const [text, setText] = useState('')
  const [goodText, setGoodText] = useState(true)
  const [everythingIsOk, setEverythingIsOk] = useState(true)
  const [sendMail, setSendMail] = useState(false)
  const [hover, setHover] = useState(false)

  const mail_to = process.env.REACT_APP_MAIL
  const productionEnv = process.env.REACT_APP_PROD_ENV
  const endpoint = process.env.REACT_APP_API_ENDPOINT
  const url = () => {
    // if (productionEnv === "prod") {
    //   return "https://application.ona.fr" + endpoint
    // }
    return "https://preprod.application.ona.fr" + endpoint
  }

  const reset = () =>{
    setSendMail(()=>false)
    setInit(() => true)
    setText(() => '')
    setTopic(() => '')
  }

  const checkName = (e) =>{
    if (e.length < 2) {
      setGoodName(() =>false)
      return 0
    } else {
      setGoodName(true)
      return 1
    }
  }

  const checkMail = (e) =>{
    if (!e.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
      setGoodMail(false)
      return 0
    } else {
      setGoodMail(true)
      return 1
    }
  }

  const checkTopic = (e) =>{
    if (e.length < 2) {
      setGoodTopic(false)
      return 0
    } else {
      setGoodTopic(true)
      return 1
    }
  }

  const checkText = (e) =>{
    if (e.length < 8) {
      setGoodText(false)
      return 0
    } else {
      setGoodText(true)
      return 1
    }
  }

  const checkData = () => {
    let check = 0
    setTimeout(()=>{
      setEverythingIsOk(() => true)
    },1200)
    if (init) {
      check = check + checkName(name)
      check = check + checkMail(mail)
      check = check + checkTopic(topic)
      check = check + checkText(text)
      setInit(() => false)
      if(check === 4){
        setEverythingIsOk(()=>true)
        setSendMail(() => true)
        activeMail()
        return true;
      }else{
        setEverythingIsOk(() => false)
        setSendMail(() => false)
        return false;
      }
    }
    if ((!goodName || !goodMail || !goodTopic || !goodText)) {
      setEverythingIsOk(() => false)
    } else {
      setEverythingIsOk(() => true)
      activeMail()
      setSendMail(() => true)
    }
  }

  const activeMail = () => {
    axios.post(url(), {
      to: [mail_to],
      from: {
        address: mail,
        name: name,
      },
      subject: 'Ona.fr contact : ' + topic,
      message: text
    }, {
      headers: {
        "Content-Type": 'application/json'
      }
    })
  }

  useEffect(() =>{
    if(goodMail === false){
      checkMail(mail)
    }
  },[mail])

  useEffect(() =>{
    if(goodName === false){
      checkName(name)
    }
  },[name])

  useEffect(() =>{
    if(goodTopic === false){
      checkName(topic)
    }
  },[topic])

  useEffect(() =>{
    if(goodText === false){
      checkText(text)
    }
  },[text])

  return(
    <Grid className="section">
      <Grid className="article">
        <Grid className="header">
          <img src={desktop}
               className="desktopLogo"/>
        </Grid>
        <Grid className="headerR">
          <img src={underConstructionR}
               className="smartLogo"/>
        </Grid>
        <Grid className="textDiv">
          <p className="maintenanceTxt">Notre site est en cours de développement.</p>
          {!sendMail?
            <>
              <p className="contactTxtFirst">Vous souhaitez nous contacter&nbsp;: </p>
              <p className="obligatoire">*Champs obligatoires</p>
            </>
            :
            <p className="contactTxt">"Nous avons bien reçu votre message ..."</p>
          }
        </Grid>
        <Grid className="form">
          {!sendMail?
            <form>
              <Grid className="inlineField">
                <TextField
                  focused={true}
                  className="inlineTxtFieldName"
                  id="standard-basic"
                  value={name}
                  error={!goodName}
                  helperText={goodName ? '' : 'Minimum 2 lettres'}
                  label="Votre nom*"
                  placeholder="Ecrivez votre nom"
                  InputLabelProps={{
                    style: { color: '#3691D0' },
                  }}
                  onChange={(e)=>{
                    setName(()=> e.target.value)
                  }}
                  onBlur={(e)=>{
                    if(!init){
                      checkName(e.target.value)
                    }
                  }}
                  variant="standard" />
                <TextField
                  focused={true}
                  className="inlineTxtFieldMail"
                  value={mail}
                  id="mailField"
                  error={!goodMail}
                  helperText={goodMail ? '' : 'Format non valide'}
                  onChange={(e)=>{
                    setMail(() => e.target.value)
                  }}
                  onBlur={(e) =>{
                    if(!init){
                      checkMail(e.target.value)
                    }}}
                  placeholder="monadressemail@mail.com"
                  label="Votre adresse mail*"
                  InputLabelProps={{
                    style: { color: '#3691D0' },
                  }}
                  variant="standard" />
              </Grid>
              <Grid className="formBottom">
                <TextField
                  focused={true}
                  className="inlineTxt"
                  id="objetField"
                  error={!goodTopic}
                  value={topic}
                  helperText={goodTopic ? '' : 'Minimum 2 lettres'}
                  placeholder="Pourquoi souhaitez-vous nous contacter ?"
                  label="Objet de la prise de contact*"
                  InputLabelProps={{
                    style: { color: '#3691D0' },
                  }}
                  onChange={(e)=>{
                    setTopic(()=> e.target.value)
                  }}
                  onBlur={(e)=>{
                    if(!init){
                      checkTopic(e.target.value)
                    }}
                  }
                  variant="standard" />
                <TextField
                  focused={true}
                  placeholder="Ecrivez votre message ici"
                  rows={3}
                  multiline
                  value={text}
                  error={!goodText}
                  helperText={goodText ? '' : 'Minimum 10 lettres'}
                  id="messageField"
                  label="Votre message*"
                  InputLabelProps={{
                    style: { color: '#3691D0' },
                  }}
                  onChange={(e)=>{
                    setText(()=> e.target.value)
                  }}
                  onBlur={(e)=>{
                    if(!init) {
                      checkText(e.target.value)
                    }
                  }}
                  variant="standard" />
              </Grid>
              <Grid className="divBtn">
                <Grid className='btnContainer'>
                  <Button
                    variant="contained"
                    disableElevation={false}
                    className="sendBtn"
                    color="primary"
                    onMouseEnter={() => {setHover(()=>true)}}
                    onMouseLeave={() => {setHover(() => false)}}
                    disabled={!everythingIsOk}
                    style={{
                      borderRadius: 35,
                      color: 'white',
                      fontSize: '10px',
                      position: 'absolute',
                      width: '100%',
                      height: '35px',
                      backgroundColor: "#154E76",
                    }}
                    onClick={(e) =>{
                      checkData()
                    }}>
                    {everythingIsOk? 'Envoyer' : 'Vérifier les données'}
                  </Button>
                  {hover ?
                    <Grid className="smiley">
                      <Smiley/>
                    </Grid>
                    :
                    null
                  }
                </Grid>
              </Grid>

            </form>
            :
            <>
              <Grid id="validationMail">
                <Grid id="validationPng">
                  <Validation />
                </Grid>
                <Grid>
                  <p>
                   " ... notre équipe vous contactera très prochainement.
                    A bientôt "
                  </p>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                id="btnRecontact"
                disabled={!everythingIsOk}
                style={{
                  borderRadius: 35,
                  fontSize: '10px',
                  color:' #3691D0',
                  height: '35px',
                }}
                onClick={()=>{
                  reset()
                }}>
                +  Nouveau formulaire de contact
              </Button>
            </>
          }
        </Grid>

      </Grid>
      <Grid className={"aside"}>
        <img src={underConstruction}
             className="underConstruction"/>
      </Grid>
    </Grid>
  )
}
export default Home;
